export default {
    // Endpoints
    riskQuestionEndpoint: process.env.VUE_APP_ROOT_API+'/api/risk-questions',
    practiceEndpoint: process.env.VUE_APP_ROOT_API+'/api/practices',
    practiceDashboardEndpoint: process.env.VUE_APP_ROOT_API+'/api/dashboards/practice',
    practiceDownloadEndpoint: process.env.VUE_APP_ROOT_API+'/api/download/practice',
    taskEndpoint: process.env.VUE_APP_ROOT_API+'/api/tasks',
    faqEndpoint: process.env.VUE_APP_ROOT_API+'/api/faqs',
    taskReviewEndpoint: process.env.VUE_APP_ROOT_API+'/api/review/tasks',
    userTaskEndpoint: process.env.VUE_APP_ROOT_API+'/api/user/tasks',
    taskDashboardEndpoint: process.env.VUE_APP_ROOT_API+'/api/dashboards/tasks',
    ownerEndpoint: process.env.VUE_APP_ROOT_API+'/api/owners',
    clientEndpoint: process.env.VUE_APP_ROOT_API+'/api/clients',
    importClientEndpoint: process.env.VUE_APP_ROOT_API+'/api/import/clients',
    clientOwnerEndpoint: process.env.VUE_APP_ROOT_API+'/api/client-owners',
    clientSecondaryOwnerEndpoint: process.env.VUE_APP_ROOT_API+'/api/client-secondary-owners',
    contractorEndpoint: process.env.VUE_APP_ROOT_API+'/api/contractors',
    staffEndpoint: process.env.VUE_APP_ROOT_API+'/api/staff',
    userEndpoint: process.env.VUE_APP_ROOT_API+'/api/users',
    userChangePasswordEndpoint: process.env.VUE_APP_ROOT_API+'/api/change-password',
    userAcceptTC: process.env.VUE_APP_ROOT_API+'/api/accept-tc',
}