import BaseService from "@core/services/baseService";

export default class ContractorService extends BaseService{
    createContractor(...args){
        return this.axiosIns.post(this.formConfig.contractorEndpoint, ...args)
    }

    updateContractor(...args){
        return this.axiosIns.patch(this.formConfig.contractorEndpoint+"/"+args[0].id, ...args)
    }

    getContractor(...args){
        return this.axiosIns.get(this.formConfig.contractorEndpoint+"/"+args[0].id, ...args)
    }

    deleteContractor(...args){
        return this.axiosIns.delete(this.formConfig.contractorEndpoint+"/"+args[0].id)
    }

    getByPractice(...args){
        return this.axiosIns.get(this.formConfig.contractorEndpoint, {params: {'practice_id': args[0].practice_id}})
    }
}